import * as React from 'react';
import { Box, Button, Typography, TextField, CircularProgress } from '@mui/material';
import httpClient from '../utils/httpService';
import CustomDialog from '../components/CustomDialog/CustomDialog';
import DialogVerifierServices from '../components/CustomDialog/VerifierServices/VerifierServices';
import { DLRResponse, GS1Link, GS1LinkSet, GS1LinkType, GS1MimeType } from '../models/gs1';
import { isValidStringPattern, replaceSpecialCharacters } from '../utils/helpers';
import { ScreenStatus } from '../models/common';

const mockNLISIDs = ['NH020188LEJ00005', 'NH020188LEJ00008', 'NH020188LEJ00012'];
const mockPICs = mockNLISIDs.map((item) => item.substring(0, 8));

/**
 * Scanning NLISID component is used to scan the NLISID and show information about the NLISID
 */
const ScanningNLISID = () => {
    const [NLISID, setNLISID] = React.useState<string>('');
    const [currentScreen, setCurrentScreen] = React.useState<string>(ScreenStatus.INITIAL_SCAN);
    const [passportLink, setPassportLink] = React.useState<GS1Link | null>(null);
    const [verifierServices, setVerifierServices] = React.useState<GS1Link[]>([]);
    const [openDialogVerifier, setOpenDialogVerifier] = React.useState<boolean>(false);
    const [openDialogErrorCode, setOpenDialogErrorCode] = React.useState<boolean>(false);
    const [errorText, setErrorText] = React.useState<string>('');
    const [NLISIDTextDisplay, setNLISIDTextDisplay] = React.useState<string>('');

    /**
     * handle scan NLISID code
     */
    const handleScan = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (!mockPICs.includes(NLISID.substring(0, 8))) {
            setErrorText('Invalid PIC number');
            return;
        }

        setErrorText('');
        setNLISIDTextDisplay(NLISID);
        setCurrentScreen(ScreenStatus.LOADING_FETCHING_DATA);
        handleNLISIDData();
    };

    /**
     * fetch NLIS data
     * ! This is mock data
     * TODO replace with api call to get NLIS data
     */
    const fetchNLISPIC = async (NLISID: string) => {
        const response = await Promise.resolve([
            {
                Value: {
                    Offset: 0,
                    Limit: 1,
                    Total: 1,
                    SubSet: [
                        {
                            PIC: 'PICTEST3',
                            PICName: 'NLIS TestPIC',
                            PICRegisterId: 1,
                            BusinessName: '',
                            FirstName: 'JOHN',
                            LastName: 'DOE',
                            Town: 'NORTH SYDNEY',
                            Species: [
                                {
                                    ShortCode: 'C',
                                },
                                {
                                    ShortCode: 'S',
                                },
                            ],
                            PropertyTypes: [
                                {
                                    ShortCode: 'PR',
                                },
                            ],
                            Brands: [
                                {
                                    ShortCode: '5UA0',
                                },
                            ],
                            RegisteredState: 'NSW',
                            AdminStatus: {
                                ShortCode: 'A',
                                Description: 'Active',
                            },
                            ShireCode: 'CT',
                            ContactDetails: [
                                {
                                    FirstName: 'JOHN',
                                    LastName: 'DOE',
                                    ContactTypeShortCode: 'PICMANAGERCONTACT',
                                },
                            ],
                            DigitalLinkResolver: `${process.env.REACT_APP_FEEDLOT_DLR_API_URL}/nlisid/${NLISID}?linkType=all`,
                        },
                    ],
                },
            },
        ]);
        return response;
    };

    /**
     * handle NLISID data
     */
    const handleNLISIDData = React.useCallback(async () => {
        if (!mockNLISIDs.includes(NLISID)) {
            setOpenDialogErrorCode(true);
            setCurrentScreen(ScreenStatus.INITIAL_SCAN);
            return;
        }

        const data = await fetchNLISPIC(NLISID);
        const subset = data[0]?.['Value']['SubSet'].find((item) => item);
        await handleDLRData(subset!.DigitalLinkResolver);
    }, [NLISID]);

    /**
     * handle scan DLR data
     * @param url this url api to get data DRL
     */
    const handleDLRData = async (url: string) => {
        try {
            const data = await httpClient.get<DLRResponse>(url);
            const passports = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.certificationInfo],
            )![GS1LinkType.certificationInfo];

            const services = (data['linkset'] as GS1LinkSet[]).find(
                (linkSet: GS1LinkSet) => linkSet[GS1LinkType.verificationService],
            )![GS1LinkType.verificationService];

            const passport = passports!.find((item) => item.type === GS1MimeType.json);
            setPassportLink(passport!);
            setVerifierServices(services!);
            setCurrentScreen(ScreenStatus.RESPONSE_DATA_SUCCESS);
        } catch (err) {
            setCurrentScreen(ScreenStatus.RESPONSE_DATA_FAIL);
        }
    };

    /**
     * handle change scan value
     */
    const handleChangeScanValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        // only allow uppercase and number
        const acceptedValue = replaceSpecialCharacters(e.target.value).toUpperCase();
        setNLISID(acceptedValue);

        // check length of entry
        const checkEntryLength = isValidStringPattern(acceptedValue, 16);

        if (!checkEntryLength) {
            setErrorText('Data entry should have 16 characters');
            return;
        }

        setErrorText('');
    };

    /**
     * handle close dialog verifier
     */
    const handleCloseDialogVerifier = () => {
        setOpenDialogVerifier(false);
    };

    /**
     * handle close dialog when code not found
     */
    const handleCloseDialogErrorFetchProductData = () => {
        setOpenDialogErrorCode(false);
    };

    /**
     * render component following state of screen changes
     */
    const renderComponent = () => {
        return React.useMemo(() => {
            switch (currentScreen) {
                case ScreenStatus.LOADING_FETCHING_DATA:
                    return <CircularProgress size={24} />;
                case ScreenStatus.RESPONSE_DATA_SUCCESS:
                    return (
                        <Box sx={{ textAlign: 'center' }}>
                            <Typography>Information found for the NLISID: {NLISIDTextDisplay}</Typography>
                            <Button onClick={() => setOpenDialogVerifier(true)}>{passportLink?.title}</Button>
                        </Box>
                    );
                default:
                    return <></>;
            }
        }, [currentScreen]);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: { xs: 'auto', md: '80vh' },
                gap: 2, //16px gap
            }}
            component={'form'}
            onSubmit={handleScan}
        >
            <TextField
                value={NLISID}
                helperText={errorText}
                error={errorText ? true : false}
                label='NLISID'
                variant='standard'
                onChange={handleChangeScanValue}
                inputProps={{ style: { textTransform: 'uppercase' } }}
            />

            <Button type='submit' variant='contained' disabled={errorText || NLISID === '' ? true : false}>
                Scan
            </Button>
            {/* Show information when have input and scan success */}
            {renderComponent()}
            {openDialogVerifier && passportLink && (
                <DialogVerifierServices
                    open={openDialogVerifier}
                    close={handleCloseDialogVerifier}
                    data={{ passportLink, verifierServices }}
                />
            )}

            {openDialogErrorCode && (
                <CustomDialog
                    title='Product not found'
                    open={openDialogErrorCode}
                    onClose={handleCloseDialogErrorFetchProductData}
                />
            )}
        </Box>
    );
};

export default ScanningNLISID;
