import axios, { AxiosInstance, AxiosResponse } from 'axios';
export const httpService: AxiosInstance = axios.create({});

/**
 * handle response http service
 */
httpService.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    },
);

const httpClient = {
    /**
     * make get request
     * @param url this is api url
     * @param params this is query params
     * @param headers this is request headers
     */
    get: async <T>(url: string, params?: object, headers?: Record<string, string>): Promise<T> => {
            const response: AxiosResponse<T> = await httpService.get(url, {
                params,
                headers,
            });
            return response.data;
        
    },
    /**
     * make post request
     * @param url this is api url
     * @param data this is body data
     * @param params this is query params
     * @param headers this is request headers
     */
    post: async <T>(
        url: string,
        data?: object,
        params?: string,
        headers?: Record<string, string>,
    ): Promise<T> => {
            const response: AxiosResponse<T> = await httpService.post(url, data, {
                params,
                headers,
            });
            return response.data;
        
    },
};

export default httpClient;
