/**
 * convertStringToBase64 converts a string to base64
 */
export function convertBase64ToString(base64: string) {
    return Buffer.from(base64, 'base64').toString('utf8');
}

/**
 * detectDevice detects the type of device based on the user agent
 */
export function detectDevice(userAgent: string) {
    const userAgentLowerCase = userAgent.toLowerCase();

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgentLowerCase)) {
        return 'mobile';
    } else if (/mac|win/i.test(userAgentLowerCase)) {
        return 'laptop';
    } else {
        return 'unknown';
    }
}

/**
 * replaceSpecialCharacters replaces all special characters in a string with an empty string
 */
export function replaceSpecialCharacters(str: string) {
    return str.replace(/[\W_]/gi, '');
}

/**
 * isValidStringPattern checks if a string matches a given pattern
 */
export function isValidStringPattern(str: string, length: number) {
    const regexPattern = new RegExp(`^[A-Z0-9]{${length}}$`);
    return regexPattern.test(str);
}
