import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Layout from './Layout';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import FallbackErrorContent from '../components/FallbackErrorContent/FallbackErrorContent';

function App() {
    return (
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={FallbackErrorContent}>
                <Header />
                <Layout />
                <Footer />
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
